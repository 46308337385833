import React, {
  ComponentProps,
  useCallback,
  useState,
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
} from 'react'
import { useController, useFormContext } from 'react-hook-form'

import BaseAutocompleteInput, {
  BaseAutocompleteProps,
} from './BaseAutocompleteInput'
import NoResultsCopy from '../Search/NoResultsCopy'
import Input from '../TripModal/TripItems/Common/Fields/RHFInput'

import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import useAirportTypeahead from 'hooks/GlobalSearch/useAirportTypeahead'
import { useAirport } from 'tripPlanner/hooks/api/flights'

interface Props extends ComponentProps<typeof Input> {
  label?: string
  name: string
  onAirportSelect: (airport: App.AirportLocation) => void
  placeholder?: string
  noParentAirports?: boolean
}

const CHARACTER_THRESHOLD = 1
const DEBOUNCE_DELAY = 300
const MAX_RESULTS = 5
const NO_RESULTS_MESSAGE = 'Try searching for a different airport.'

const presentationSelector: BaseAutocompleteProps<App.AirportLocation>['presentationSelector'] =
  (option) => ({
    title: option.airportName,
    subtitle: `${option.cityDesc} (${option.airportCode})`,
    startIcon: <LinePlaneFlyIcon colour="neutral-one" />,
  })

const initValueFormat = (airport?: App.AirportLocation) =>
  airport ? `${airport.airportName} (${airport.airportCode})` : ''

function AirportAutocomplete({
  name,
  onFocus,
  onAirportSelect,
  placeholder,
  label,
  noParentAirports,
  required,
}: Props) {
  const [searchPhrase, setSearchPhrase] = useState('')

  const { setValue, control } = useFormContext()
  const {
    field: { ref, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: 'Please select an airport from your search.',
    },
  })

  const {
    hasResults,
    isLoading: predictionsLoading,
    results,
  } = useAirportTypeahead(searchPhrase, {
    noParentAirports,
    debounceDelay: DEBOUNCE_DELAY,
    characterThreshold: CHARACTER_THRESHOLD,
    maxResults: MAX_RESULTS,
  })

  const airportQuery = useAirport({
    code: value,
    onSuccess: (airport) => {
      if (airport) {
        setSearchPhrase(initValueFormat(airport))
        setValue(name, airport.airportCode)
      } else {
        setValue(name, undefined)
      }
    },
    onError: () => {
      setValue(name, undefined)
    },
  })

  useEffect(() => {
    // Attempts to set airport on mount if data exists
    if (airportQuery.data) {
      setSearchPhrase(initValueFormat(airportQuery.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = useCallback(
    (airport: App.AirportLocation) => {
      setValue(name, airport.airportCode, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      })
      setSearchPhrase(initValueFormat(airport))
      onAirportSelect?.(airport)
    },
    [name, setValue, onAirportSelect],
  )

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSearchPhrase('')
      setValue(name, undefined)
      onFocus?.(e)
    },
    [onFocus, setValue, name],
  )

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSearchPhrase(e.currentTarget.value)
    },
    [],
  )

  return (
    <BaseAutocompleteInput
      ref={ref}
      required={required}
      value={searchPhrase}
      error={error?.message}
      label={label}
      placeholder={placeholder}
      canRenderDropdown={searchPhrase.length > CHARACTER_THRESHOLD}
      canRenderNoResult={!hasResults && !predictionsLoading}
      name={name}
      noResultComponent={
        <NoResultsCopy
          message={NO_RESULTS_MESSAGE}
          searchPhrase={searchPhrase}
        />
      }
      startIcon={<LineMapMarkerIcon />}
      onChange={handleChange}
      onFocus={handleFocus}
      onOptionSelect={handleSelect}
      options={results}
      presentationSelector={presentationSelector}
      isLoading={predictionsLoading}
    />
  )
}

export default AirportAutocomplete
