import { uniqueBy } from 'lib/array/arrayUtils'
import { useTripMetadata } from './trip'
import { UseQueryOptions, UseQueryResult, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { getOrderItemFlightDetails } from 'api/order'
import * as OrderKeys from '../reactQueryKeys/orders'
import { NON_TRIP_STALE_TIME } from './common'
import { getAirlines, getAirportsSearch, getFlightPrice } from 'api/flights'
import { Airline } from 'components/Flights/types'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { FLIGHTS_SEARCH_DAY_MIN_ADVANCE, FLIGHTS_SEARCH_DAY_MAX_ADVANCE } from 'tripPlanner/config/flights'

type FlightDetails = {
  journeyFares: {
    tripType: string
    fares: Array<App.JourneyV2>
  }
  travellers?: Array<App.OrderTraveller>
  providerBookingReference?: any
  pnrId?: string
}

export const useTripAirports = ({
  tripId,
}: {
  tripId: string
}): Array<App.AirportLocation> => {
  const { data: tripMetadata } = useTripMetadata({ tripId })

  return uniqueBy(
    (tripMetadata?.locations || []).flatMap((location) => {
      return location.airports.map(
        (airport): App.AirportLocation => ({
          ...airport,
          cityAirportName: airport.cityAirportName || '',
        }),
      )
    }),
    (airport) => airport.airportCode,
  )
}

export const useFlightDetailsForItem = ({
  orderId, itemId, enabled = true,
}: {
  orderId: string
  itemId: string
  enabled?: boolean
}): UseQueryResult<FlightDetails> => {
  return useQuery(
    OrderKeys.flightDetailsOrderAndItem(orderId, itemId),
    () => getOrderItemFlightDetails({ orderId, itemId }),
    {
      enabled,
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useFlightDetailsForItems = ({
  itemIds, orderId, enabled = true,
}: {
  itemIds: Array<string>
  orderId: string
  enabled?: boolean
}): UseQueryResult<Array<FlightDetails>> => {
  const queryClient = useQueryClient()

  return useQuery(
    OrderKeys.flightDetailsOrderAndItems(orderId, itemIds),
    async() => {
      if (itemIds.length === 0) {
        return []
      }

      return await Promise.all(
        itemIds.map((itemId) => getOrderItemFlightDetails({
          itemId,
          orderId,
        }).then((resp) => {
          queryClient.setQueryData(
            OrderKeys.flightDetailsOrderAndItem(orderId, itemId),
            resp,
          )
          return resp
        }),
        ),
      )
    },
    {
      enabled,
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useAirport = ({
  code, ...options
}: Omit<
  UseQueryOptions<
    Array<App.AirportLocation> | undefined, unknown, App.AirportLocation | undefined
  > & {
    code: string
  }, 'select'
>) => {
  return useQuery<
    Array<App.AirportLocation> | undefined,
    unknown,
    App.AirportLocation | undefined
  >(['airport', code], () => getAirportsSearch(code), {
    select: (data) => data ? data.find((d) => d.airportCode === code) : undefined,
    staleTime: NON_TRIP_STALE_TIME,
    ...options,
  })
}

export const useAirports = ({ codes }: { codes: Array<string>}) => {
  return useQueries<Array<App.AirportLocation>>({
    queries: codes.map((code) => ({
      queryKey: ['airport', code],
      queryFn: () => getAirportsSearch(code),
      select: (data: any) => data ? data.find((d: any) => d.airportCode === code) : undefined,
      staleTime: NON_TRIP_STALE_TIME,
    })),
    // TODO: Figure out how to use the type correctly so we don't need to use `as`.
    // I tried, but couldn't figure it out.
  }) as Array<UseQueryResult<App.AirportLocation | undefined, unknown>>
}

export const useAirlineByName = ({
  airlineName, enabled, onError, onSuccess,
}: {
  airlineName: string | undefined
  enabled?: boolean
  onSuccess?: (airline: Airline | undefined) => void
  onError?: () => void
}): UseQueryResult<Airline | undefined, unknown> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['airline', airlineName],
    async() => {
      if (!airlineName) {
        return undefined
      }
      const airlines = await getAirlines(airlineName)

      airlines.forEach((airline) => {
        queryClient.setQueryData(['airline', airline.airlineCode], airline)
        queryClient.setQueryData(['airline', airline.airlineName], airline)
      })

      return airlines.find((airline) => airline.airlineName === airlineName)
    },
    { staleTime: NON_TRIP_STALE_TIME, onSuccess, onError, enabled },
  )
}

export const useAirlineByCode = ({
  code, onError, onSuccess,
}: {
  code: string | undefined
  onSuccess?: (airline: Airline | undefined) => void
  onError?: () => void
}): UseQueryResult<Airline | undefined, unknown> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['airline', code],
    async() => {
      if (!code) {
        return undefined
      }
      const airlines = await getAirlines(code)

      airlines.forEach((airline) => {
        queryClient.setQueryData(['airline', airline.airlineCode], airline)
        queryClient.setQueryData(['airline', airline.airlineName], airline)
      })

      return airlines.find((airline) => airline.airlineCode === code)
    },
    { staleTime: NON_TRIP_STALE_TIME, onSuccess, onError },
  )
}

export const useLowestFlightPrice = ({
  originAirportCode, destinationAirportCode, currencyCode, regionCode, shouldQuery, nights, numberOfAdults, checkIn, checkOut,
}: {
  originAirportCode: string
  destinationAirportCode: string
  currencyCode: string
  regionCode: string
  shouldQuery: boolean
  nights?: number
  numberOfAdults?: number
  checkIn?: string
  checkOut?: string
}): UseQueryResult<number | undefined> => {
  return useQuery(
    [
      'lowestFlightPrice',
      originAirportCode,
      destinationAirportCode,
      currencyCode,
      regionCode,
      checkIn,
      checkOut,
      nights,
      numberOfAdults,
    ],
    () => {
      if (!shouldQuery) return Promise.resolve(undefined)

      const startDate = moment().add(FLIGHTS_SEARCH_DAY_MIN_ADVANCE, 'day')
      const endDate = moment().add(FLIGHTS_SEARCH_DAY_MAX_ADVANCE, 'day')

      return getFlightPrice({
        startDate: checkIn ?? startDate.format(ISO_DATE_FORMAT),
        endDate: checkOut ?? endDate.format(ISO_DATE_FORMAT),
        numberOfAdults: numberOfAdults ?? 1,
        numberOfChildren: 0,
        numberOfInfants: 0,
        nights: nights ?? 1,
        origin: originAirportCode,
        destination: destinationAirportCode,
        currency: currencyCode,
        region: regionCode,
      })
    },
    { staleTime: NON_TRIP_STALE_TIME },
  )
}
