import moment from 'moment'
import { rem } from 'polished'
import React, { useCallback, useContext, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import TimeInput from './TimeInput'

import Breakpoint from 'components/Common/Breakpoint'
import FakeInput from 'components/Common/Form/Input/FakeInput'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import Group from 'components/utils/Group'
import { DMY_CASUAL_SHORT_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'
import ModalContext from 'contexts/ModalContext'
import useToggle from 'hooks/useToggle'
import DateRangeDropdown from 'tripPlanner/components/TripModal/Common/DateRangeDropdown'
import MobileSelectDatesSelfClose from 'tripPlanner/components/TripModal/Common/MobileSelectDatesSelfClose'

const OverlappingGroup = styled(Group)`
  * + * {
    margin-inline-start: -1px;
  }
`

const DateInput = styled(FakeInput)`
  &.date-wrap {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: ${rem(160)};
    height: ${rem(48)};
  
    &:not(.error):not(.disabled):focus-within {
      z-index: 1;
    }
  }
`
const StyledTimeInput = styled(TimeInput)`
  min-width: ${rem(91)};
`

export function AccommodationDateTimeFields() {
  return (
    <>
      <Breakpoint max="mobile">
        <MobileAccommodationDateTimeFields />
      </Breakpoint>
      <Breakpoint min="tablet">
        <DesktopAccommodationDateTimeFields />
      </Breakpoint>
    </>
  )
}

function MobileAccommodationDateTimeFields() {
  const { watch, setValue, register } = useFormContext()
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const dateDropdownTrigger = useRef<HTMLDivElement | null>(null)

  const onDatesChange = useCallback(
    (dates: { startDate: moment.Moment; endDate?: moment.Moment | null }) => {
      setValue('startDate', dates.startDate.format(ISO_DATE_FORMAT), {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue('endDate', dates.endDate?.format(ISO_DATE_FORMAT), {
        shouldDirty: true,
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const showModal = useContext(ModalContext)
  const startDateMoment = startDate ?
    moment(startDate, ISO_DATE_FORMAT) :
    undefined
  const endDateMoment = endDate ? moment(endDate, ISO_DATE_FORMAT) : undefined

  const onClearDates = useCallback(() => {
    setValue('startDate', undefined)
    setValue('endDate', undefined)
  }, [setValue])

  const onClickDateInput = useCallback(() => {
    showModal(
      <ModalBase height="full">
        <MobileSelectDatesSelfClose
          title="Select dates"
          doneLabel="Apply"
          initialStartDate={startDateMoment}
          initialEndDate={endDateMoment}
          onChange={(startDate, endDate) => {
            onDatesChange({ startDate, endDate })
          }}
          onRemove={onClearDates}
          showTripDates
          startLabel="Check-in"
          endLabel="Check out"
        />
      </ModalBase>,
    )
  }, [endDateMoment, onClearDates, onDatesChange, showModal, startDateMoment])

  return (
    <Group direction="vertical" gap={16} ref={dateDropdownTrigger}>
      <OverlappingGroup
        direction="horizontal"
        horizontalAlign="stretch"
        verticalAlign="end"
      >
        <DateInput
          label="Check-in"
          value={startDate && moment(startDate).format(DMY_CASUAL_SHORT_FORMAT)}
          placeholder="Select date"
          onClick={onClickDateInput}
          startIcon={<LineCalendarIcon />}
          data-testid="check-in-date"
          inputWrapClassName="date-wrap"
        />
        <TimeInput
          {...register('startTime')}
          label="Time"
          placeholder="Select time"
          required
          defaultValue="15:00"
          hasIcon={false}
          showClear={false}
        />
      </OverlappingGroup>
      <OverlappingGroup
        direction="horizontal"
        horizontalAlign="stretch"
        verticalAlign="end"
      >
        <DateInput
          label="Check-out"
          value={endDate && moment(endDate).format(DMY_CASUAL_SHORT_FORMAT)}
          placeholder="Select date"
          onClick={onClickDateInput}
          startIcon={<LineCalendarIcon />}
          data-testid="check-out-date"
          inputWrapClassName="date-wrap"
        />
        <TimeInput
          {...register('endTime')}
          label="Time"
          placeholder="Select time"
          required
          defaultValue="10:00"
          hasIcon={false}
          showClear={false}
        />
      </OverlappingGroup>
    </Group>
  )
}

function DesktopAccommodationDateTimeFields() {
  const { watch, setValue, register } = useFormContext()
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const dateDropdownTrigger = useRef<HTMLDivElement | null>(null)

  const {
    value: dateDropdownOpen,
    toggle: toggleDateDropdown,
    off: closeDateDropdown,
  } = useToggle()

  const onDatesChange = useCallback(
    (dates: { startDate: moment.Moment; endDate?: moment.Moment | null }) => {
      setValue('startDate', dates.startDate.format(ISO_DATE_FORMAT), {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue('endDate', dates.endDate?.format(ISO_DATE_FORMAT), {
        shouldDirty: true,
        shouldValidate: true,
      })
      closeDateDropdown()
    },
    [closeDateDropdown, setValue],
  )

  const startDateMoment = startDate ?
    moment(startDate, ISO_DATE_FORMAT) :
    undefined
  const endDateMoment = endDate ? moment(endDate, ISO_DATE_FORMAT) : undefined

  const onClickDateInput = useCallback(() => {
    toggleDateDropdown()
  }, [toggleDateDropdown])

  const onClearDates = useCallback(() => {
    setValue('startDate', undefined)
    setValue('endDate', undefined)
    closeDateDropdown()
  }, [closeDateDropdown, setValue])

  return (
    <>
      <Group direction="horizontal" gap={16} ref={dateDropdownTrigger}>
        <OverlappingGroup
          direction="horizontal"
          horizontalAlign="stretch"
          verticalAlign="end"
          gap={0}
        >
          <DateInput
            label="Check-in"
            value={
              startDate && moment(startDate).format(DMY_CASUAL_SHORT_FORMAT)
            }
            placeholder="Select date"
            onClick={onClickDateInput}
            startIcon={<LineCalendarIcon />}
            data-testid="check-in-date"
          />
          <StyledTimeInput
            {...register('startTime')}
            placeholder="Select time"
            required
            defaultValue="15:00"
            hasIcon={false}
            showClear={false}
            onFocus={closeDateDropdown}
            testIdPrefix="check-in-time"
          />
        </OverlappingGroup>
        <OverlappingGroup
          direction="horizontal"
          horizontalAlign="stretch"
          verticalAlign="end"
          gap={0}
        >
          <DateInput
            label="Check-out"
            value={endDate && moment(endDate).format(DMY_CASUAL_SHORT_FORMAT)}
            placeholder="Select date"
            onClick={onClickDateInput}
            startIcon={<LineCalendarIcon />}
            data-testid="check-out-date"
          />
          <StyledTimeInput
            {...register('endTime')}
            placeholder="Select time"
            required
            defaultValue="10:00"
            hasIcon={false}
            showClear={false}
            onFocus={closeDateDropdown}
            testIdPrefix="check-out-time"
          />
        </OverlappingGroup>
      </Group>
      <DateRangeDropdown
        startDate={startDateMoment}
        endDate={endDateMoment}
        triggerRef={dateDropdownTrigger}
        isOpen={dateDropdownOpen}
        onDatesChange={onDatesChange}
        onClear={onClearDates}
        onClose={closeDateDropdown}
        startLabel="Check-in"
        endLabel="Check out"
        rangeType="nights"
      />
    </>
  )
}
